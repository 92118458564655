import moment from "moment";

const initialState = {
  nextShift: null,
  teams: [],
  checkIn: null,
};

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case "CHECK_OUT":
      return {
        ...state,
        prevCheckIn: {
          ...state.checkIn,
          endsAt: moment().format("HH:mm"),
        },
      };

    case "RESET_PREV_CHECK_IN":
      return {
        ...state,
        prevCheckIn: null,
      };

    case "RESET_CHECKIN":
      return {
        ...state,
        prevCheckIn: null,
        nextShift: null,
      };

    case "CHECK_IN":
      return {
        ...state,
        checkIn: action.payload,
      };

    case "PRE_CHECK_IN":
      return {
        ...state,
        nextShift: action.shift,
        checkIn: action.checkIn,
        teams: action.teams,
      };

    default:
      return state;
  }
}
