import _ from "lodash";

const initialState = {
  expires_at: null,
  refresh_token: null,
  token: null,
  user: {},
  envId: null,
};

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "INIT_APP":
    case "RESET_USER":
      return {
        ...state,
        user: null,
        user_token: null,
      };

    case "USER_AUTH_SUCCESS":
      return {
        ...state,
        user_token: action.user_token,
        user: action.user,
      };
    case "AUTH_SUCCESS":
      return {
        ...state,
        token: action.access_token,
        refresh_token: action.refresh_token,
        name: action.naam,
        expires_at: action.expires_at,
        envId: action.envId,
      };

      break;

    case "REFRESH_TOKEN":
      return {
        ...state,
        token: action.token,
        expires_at: action.expires_at,
        refresh_token: action.refresh_token,
      };

    default:
      return state;
  }
}
