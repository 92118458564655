import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { login } from "actions/auth";
import { Input } from "@eitje/form-fields-web";
import { ContextForm as Form, useForm } from "@eitje/form";
import { useCookies } from "react-cookie";
import utils from "@eitje/web_utils";
import { t } from "initializers/i18n";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { checkIn } from "actions/check_in";
import { RawDropdownPicker } from "@eitje/form-fields-web";
import "./styles/check_in.less";
import "./styles/footer_buttons.less";
import _ from "lodash";

const CheckIn = () => {
  const shift = useSelector((state) => state.records.nextShift) || {};
  const { team } = shift;
  const _teams = useSelector((state) => state.records.teams) || [];
  const [teamId, setTeamId] = useState(team?.id);
  const time = moment().format("HH:mm");

  const teams = _.uniqBy([team, ..._teams], "id").filter((i) => i);

  useEffect(() => {
    if (teams.length == 1 && !team) {
      setTeamId(teams[0].id);
    }
  }, [teams.length]);

  const navigate = useNavigate();
  const afterSucc = () => {
    navigate("app/user", {
      replace: true,
      state: { checkedIn: true },
      checkedIn: true,
    });
  };

  const cns = utils.makeCns("check-in-page", !teamId && "no-team-selected");

  return (
    <div className={cns}>
      <div className="center-page-inner">
        <p className="check-in-title">{t("checkin_start")}</p>
        <h1 className="check-in-time">{time}</h1>

        {teams.length > 0 && (
          <RawDropdownPicker
            disabled={team || teams.length == 1}
            value={teamId}
            placeholder={t("selectTeam")}
            items={teams}
            showSearch={false}
            labelField="naam"
            subTitle={"team"}
            onChange={setTeamId}
          />
        )}

        {teams.length == 0 && <p> {t("addToTeamFirst")} </p>}

        <div className="footer-buttons">
          <Link to="/app/user" className="footer-button">
            <div className="footer-button-contents">
              <img
                className="footer-button-icon"
                src="/icons/status/abort.png"
              />
              <h3 className="footer-button-text">{t("cancel")}</h3>
            </div>
          </Link>

          <div
            style={{ opacity: teamId ? 1 : 0.25 }}
            disabled={!teamId}
            textStyle={{ opacity: teamId ? 1 : 0.25 }}
            onClick={() => (!teamId ? _.noop : checkIn(teamId, afterSucc))}
            className="footer-button"
          >
            <div className="footer-button-contents">
              <img
                className="footer-button-icon"
                src="/icons/status/complete.png"
              />
              <h3 className="footer-button-text">{t("confirm")}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckIn;
