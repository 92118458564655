import Reactotron from "reactotron-react-js";
import { reactotronRedux } from "reactotron-redux";
import apisaucePlugin from "reactotron-apisauce";

let reactotron;

if (process.env.NODE_ENV !== "production") {
  reactotron = Reactotron.configure({ name: "eitje_vloer_web" })
    .use(apisaucePlugin({}))
    .use(reactotronRedux())
    .connect();
}

export default reactotron;
