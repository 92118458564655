import { backend } from "initializers/api";
import { store } from "index";
import i18n from "initializers/i18n";
import moment from "moment";

export const getTaxonomy = async () => {
  const res = await backend.get(`taxonomy`);
  if (res.ok) {
    store.dispatch({ type: "TAXONOMY", payload: res.data.taxonomy });
  }
};

export async function setLocale(language) {
  store.dispatch({ type: "SET_LANGUAGE", language });
  i18n.changeLanguage(language);
  moment.locale(language, {
    week: {
      dow: 1,
    },
  });
}
