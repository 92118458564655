import React from "react";
import { Spin } from "antd";
import { setup, configure } from "@eitje/form";
import { t } from "initializers/i18n";

const a = (text) => window.alert(a);

const DefaultInput = ({ onChange, field }) => (
  <input onChange={(e) => onChange(e.target.value)} />
);

setup({ translatorFunc: t, alert: a });

const defaultProps = { DefaultInput, LoadingEl: () => <Spin spinning /> };

const fieldRules = {
  naam: (value) => value === "baas",
};

const fieldMessages = {
  naam: "invalid name",
};

const messages = { field: fieldMessages };
const rules = { field: fieldRules };

const Form = configure({ rules, messages, defaultProps });
export default Form;
