import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "initializers/polyfill";

import "initializers/sentry";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "initializers/moment";
import "initializers/i18n";
import "initializers/form";
import "initializers/pluralize";
import "styles/general.css";
import "antd/dist/antd.min.css";
import "moment/locale/nl";
import { init } from "initializers/api";
import App from "./App";
import { Provider } from "react-redux";
import createStore from "./store";
import { BrowserRouter, useLocation } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserHistory } from "history";
import { CookiesProvider } from "react-cookie";

export const { store, persistor } = createStore();

export const history = createBrowserHistory();
init();

const ScrollToTop = ({ children }) => {
  const loc = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loc]);
  return children;
};

const render = () => {
  return ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter history={history}>
          <CookiesProvider>
            <ScrollToTop>
              <App />
            </ScrollToTop>
          </CookiesProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
};

window.onload = () => {
  render();
};

if (module.hot) {
  module.hot.accept("./App", () => {
    render();
  });
}
