import React, { useEffect, useState, useRef } from "react";
import { Button } from "antd";
import { login } from "actions/auth";
import { ContextForm as Form, useForm } from "@eitje/form";
import { useCookies } from "react-cookie";
import utils from "@eitje/web_utils";
import { t } from "initializers/i18n";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { checkIn } from "actions/check_in";
import { DropdownPicker, Input, TimePicker } from "@eitje/form-fields-web";
import { useMergeState, useAsyncEffect } from "@eitje/react-hooks";
import { checkInSelector, prevCheckInSelector } from "selectors/check_in";
import { backend } from "initializers/api";
import { checkOut } from "actions/check_in";
import "./styles/check_out.less";
import ButtonSelect from "components/button_select";
import _ from "lodash";

const CheckOut = () => {
  const [breakTime, setBreak] = useState(0);
  const [formState, setState] = useMergeState();
  const shift = useSelector((state) => state.records.nextShift) || {};
  const shiftTeam = shift.team;

  const _teams = useSelector((state) => state.records.teams) || [];
  const teams = _.uniqBy([shiftTeam, ..._teams], "id").filter((i) => i);
  const meals = useSelector((state) => state.taxonomy.meals) || [];
  const allowBreakSelect = useSelector(
    (state) => state.taxonomy.allow_break_select
  );
  const form = useRef(null);
  const checkIn = useSelector(checkInSelector);
  const loading = useSelector((state) => state.api.loading);
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    if (checkIn.id) {
      const res = await backend.get(`check_ins/${checkIn.id}`, {
        doLoad: true,
      });

      if (res.ok) {
        const { item } = res.data;
        setBreak(item.breaktime);
        form.current.setValues({
          pauze_duur: utils.minToTimeString(item.breaktime),
        });
      } else if (res.status == 410) {
        navigate("app/user", { replace: true });
      }
    }
  }, []);

  if (!checkIn) return <Navigate to="app/user" replace />;

  const { pauze_duur } = formState;

  const { start, startMoment } = checkIn;

  const endTime = moment();
  const totalTime = endTime.diff(startMoment, "m");

  const pauzeMins = utils.timeStringToMin(pauze_duur);
  const afterSucc = () => {
    navigate("app/user", { replace: true });
  };

  const valid = !pauzeMins || totalTime > pauzeMins;

  return (
    <div className="check-out-page">
      <div className="center-page-inner">
        <div className="check-in-and-out-times">
          <div className="time-wrapper">
            <p>{t("checkin_time")}</p>
            <h1>{start}</h1>
          </div>

          <p className="time-divider">|</p>

          <div className="time-wrapper">
            <p>{t("checkout_time")}</p>
            <h1>{endTime.format("HH:mm")}</h1>
          </div>
        </div>

        <Form
          setState={setState}
          mirrorFields={["pauze_duur"]}
          ref={form}
          initialValues={checkIn}
          onSubmit={(data) => checkOut(data, afterSucc)}
        >
          <DropdownPicker
            readOnly
            field="team_id"
            items={teams}
            labelField="naam"
            label="team:"
          />
          {allowBreakSelect && (
            <TimePicker
              popupClassName="select-break-time-dropdown"
              field="pauze_duur"
              label={t("breakTime")}
            />
          )}
          {!valid && (
            <h5 className="invalid-break-time-error-text">
              {t("breakTooLong")}
            </h5>
          )}
          {meals.length > 0 && <MealPicker items={meals} />}
        </Form>

        <div className="footer-buttons">
          <div
            onClick={() => navigate("user_home", { replace: true })}
            className="footer-button"
          >
            <div className="footer-button-contents">
              <img
                className="footer-button-icon"
                src="/icons/status/abort.png"
              />
              <h3 className="footer-button-text">{t("cancel")}</h3>
            </div>
          </div>

          <div
            style={{ opacity: valid ? 1 : 0.25 }}
            disabled={loading || !valid}
            textStyle={{ opacity: valid ? 1 : 0.25 }}
            onClick={() => form.current && form.current.submit()}
            className="footer-button"
          >
            <div className="footer-button-contents">
              <img
                className="footer-button-icon"
                src="/icons/status/complete.png"
              />
              <h3 className="footer-button-text">{t("confirm")}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MealPicker = ({ items }) => {
  return (
    <div className="meal-picker">
      <p className="meal-picker-title"> {t("addMeal")} </p>
      <ButtonSelect multiple field="maaltijd" items={items} />
    </div>
  );
};

export default CheckOut;
