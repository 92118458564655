import React, {
  useEffect,
  useState,
  Fragment,
  createContext,
  useContext,
  useCallback,
  useRef,
} from "react";
import moment from "moment";
import Form from "initializers/form";
import {
  DropdownPicker,
  Input,
  DatePicker,
  Switch,
} from "@eitje/form-fields-web";
import { t } from "initializers/i18n";
import { API, useWhere, useFind, useAll } from "@eitje/easy_api";
import { useMergeState } from "@eitje/react-hooks";
import Dropdown from "components/dropdown";
import { Button } from "antd";
import utils from "@eitje/web_utils";
import { useToggleState, useBoolState } from "@eitje/react-hooks";
import { history } from "index";
import { makeField, usePicker } from "@eitje/form";
import _ from "lodash";

const ButtonSelect = (props) => {
  let {
    value = [],
    minLength,
    items = [],
    single,
    minSelected,
    error,
    multiple,
    style = {},
    onChange,
    ...rest
  } = props;
  const { pickerItems, selectedBaseItem, selectedItems } = usePicker(props);
  const _onChange = (val) => {
    if (single) return onChange(utils.alwaysArray(val));
    const newVal = utils.toggle(value, val);
    if (minLength && newVal.length < minLength) return;
    onChange(utils.toggle(value, val));
  };

  return (
    <div className="eitje-button-select">
      {pickerItems.map((i) => (
        <ButtonSelectButton
          onChange={_onChange}
          active={value.includes(i.value)}
          item={i}
          {...rest}
        />
      ))}
    </div>
  );
};

const ButtonSelectButton = ({ item, onChange, readOnly, active }) => {
  const className = utils.makeCns(
    "eitje-button-select-button",
    active && "active",
    readOnly && "read-only"
  );
  const act = readOnly ? _.noop : () => onChange(item.value);
  return (
    <Button onClick={act} className={className}>
      {item.label}
    </Button>
  );
};

export default makeField(ButtonSelect, {
  className: "eitje-button-select-container",
});
