import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import I18n from "initializers/i18n";

const makeAbbr = ({ full_name, name, naam }) => {
  const _naam = full_name || name || naam;
  if (!_naam || typeof _naam !== "string") return;
  const nom = _naam.split(" ").slice(0, 2);
  if (nom.length > 1) {
    return nom
      .filter((i) => i)
      .map((v) => v[0].toUpperCase())
      .join("");
  } else {
    return _naam.slice(0, 2).toUpperCase();
  }
};

const avaPattern = /chicken.*avatar.png/;

export const UserImage = ({
  test,
  userId,
  pStyle = {},
  divProps = {},
  divClassName = "",
  extraStyle,
  textStyle,
  className = "user-avatar",
  imageStyle,
  avatarImage,
  ...rest
}) => {
  let abbr;
  let newUser;

  const user = useSelector((state) => state.auth.user);
  let { avatar } = user;
  const noAvatar = !_.isString(avatar) || avatar.match(avaPattern);
  if (noAvatar) {
    abbr = makeAbbr(user);
  }

  const cn = `user-image ${divClassName} `;

  return (
    <div className={cn} {...divProps} style={{ ...extraStyle }}>
      {noAvatar ? (
        <p style={pStyle}>{abbr}</p>
      ) : (
        <img src={avatar} className={className} />
      )}
    </div>
  );
};

const languages = ["en", "nl"];

const getBaseUrl = () => {
  let lng = I18n.language;
  if (!languages.includes(lng)) lng = "nl";
  const docsBaseUrl = `https://intercom.help/eitje/${lng}`;
  return docsBaseUrl;
};

const getUrl = (collection) => {
  const docsBaseUrl = getBaseUrl();
  const articleUrl = `${docsBaseUrl}/articles`;
  const collectionUrl = `${docsBaseUrl}/collections`;
  return collection ? collectionUrl : articleUrl;
};

export const DocsLink = ({ to, collection, ...rest }) => {
  const baseUrl = getUrl(collection);
  const href = `${baseUrl}/${to}`;
  return <a target="_blank" href={href} {...rest} />;
};
