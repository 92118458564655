import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { login } from "actions/auth";
import { Input } from "@eitje/form-fields-web";
import { ContextForm as Form, useForm } from "@eitje/form";
import { useCookies } from "react-cookie";
import utils from "@eitje/web_utils";
import { t } from "initializers/i18n";
import CodeInput from "components/code_input";
import { loginUser } from "actions/auth";
import { store } from "index";
import { DocsLink } from "components/ui";
import "./styles/user_login.less";

const UserLogin = () => {
  useEffect(() => {
    store.dispatch({ type: "RESET_CHECKIN" });
  }, []);

  return (
    <div className="user-login-page">
      <div className="center-page-inner">
        <div className="center-page-contents">
          <p className="user-login-page-title"> {t("enter_ID")} </p>
          <CodeInput
            numOnly
            rollbackOnFail
            onSubmit={(val) => loginUser(val)}
            renderPad
            autoFocus
            amtInputs={5}
          />

          <DocsLink className="find-key-help-text" to="8074587">
            {t("findID")}
          </DocsLink>
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
