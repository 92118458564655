import { backend } from "initializers/api";
import { store } from "index";
import { history } from "index";
import utils from "@eitje/web_utils";

const _checkIn = async (teamId, afterSucc) => {
  const res = await backend.post(`check_in`, { team_id: teamId });
  if (res.ok) {
    store.dispatch({ type: "CHECK_IN", payload: res.data.item });
    afterSucc();
  }
};

const _checkOut = async (prms, afterSucc) => {
  const res = await backend.post(`check_out`, { check_out: prms });
  if (res.ok) {
    store.dispatch({ type: "CHECK_OUT" });
    afterSucc();
  }
};

export const checkIn = utils.debounce(_checkIn, 1000);
export const checkOut = utils.debounce(_checkOut, 1000);

export const preCheckIn = async () => {
  const res = await backend.get(`pre_check_in`, { doLoad: true });
  if (res.ok) {
    store.dispatch({
      type: "PRE_CHECK_IN",
      teams: res.data.teams,
      checkIn: res.data.check_in,
      shift: res.data.shift,
    });
  }
};
