import { backend } from "initializers/api";
import { success } from "utils/ui";
import { store, history } from "index";

export async function login({ api_key, api_secret, device_info, env_id }) {
  const deviceVars = {};

  const resp = await backend.post("oauth/token", {
    api_key,
    env_id,
    device_info,
    api_secret,
    grant_type: "password",
    new_vloer: true,
  });

  const { data } = resp;

  if (!resp.problem) {
    const { access_token, refresh_token, naam } = data;
    const auth = {
      type: "AUTH_SUCCESS",
      access_token,
      refresh_token,
      naam,
      expires_at: data.created_at + data.expires_in,
      envId: env_id,
    };
    await store.dispatch(auth);
  }
}

export async function loginUser(pin) {
  const res = await backend.post(`oauth/token?user_specific=true`, {
    scope: "floor_user",
    grant_type: "password",
    pin,
  });

  if (res.ok) {
    const { user, access_token } = res.data;
    store.dispatch({
      type: "USER_AUTH_SUCCESS",
      user,
      user_token: access_token,
    });
    history.replace("/user_home");
  }

  return res;
}

export async function signUp(
  email,
  password,
  first_name,
  last_name,
  type,
  store
) {
  const body = {
    email,
    password,
    first_name,
    last_name,
    password_confirmation: password,
  };
  const resp = await backend.post(`users`, { user: body });
  if (!resp.problem) {
    await login();
    //await call(login, email, password, null )
  }
}

export async function refreshToken() {
  const { auth } = store.getState();
  const prms = {
    refresh_token: auth.refresh_token,
    grant_type: "refresh_token",
  };
  const resp = await backend.post(`oauth/token`, prms);
  const { data } = resp;
  if (!resp.problem) {
    store.dispatch({
      type: "REFRESH_TOKEN",
      token: data.access_token,
      refresh_token: data.refresh_token,
      expires_at: data.created_at + data.expires_in,
    });
    return {
      ...data,
      access_token: data.access_token,
      expires_at: data.created_at + data.expires_in,
    };
  } else {
    logout();
    return false;
  }
}

export async function refreshTokenIfNeeded() {
  const cs = Math.round(new Date().getTime() / 1000);
  const state = store.getState();
  if (state.auth.refresh_token && state.auth.expires_at < cs) {
    const tokenData = await refreshToken();
    return tokenData.access_token;
  } else {
    return state.auth.token;
  }
}

export async function handleRegistration(user, password) {
  login(user.email, password);
  success();
}

export function checkAuth() {
  const state = store.getState();
  return !!state.auth.refresh_token;
}

export async function logout() {
  store.dispatch({ type: "LOGOUT" });
  history && history.push("/login");
}
