import React, { Fragment, useEffect } from "react";
import Login from "pages/auth/login";
import NotFound from "pages/system/not_found";
import { Route, Routes, Navigate } from "react-router-dom";
import { PrivateOutlet, PublicOutlet, UserOutlet } from "components/routes";
import ErrorBoundary from "components/error_boundary";
import { logout } from "actions/auth";
import { Button, Spin } from "antd";
import Header from "components/header";
import { useSelector } from "react-redux";
import { getTaxonomy } from "actions/general";
import UserLogin from "pages/user_login";
import CheckIn from "pages/user/check_in";
import CheckOut from "pages/user/check_out";
import UserHome from "pages/user/home";
import { store } from "index";
import "./styles/app/reset_styles.less";
import "./styles/app/app.less";
import { setLocale } from "actions/general";

const App = () => {
  const userLoc = useSelector((state) => state.general.language);
  const loading = useSelector((state) => state.api.loading);
  useEffect(() => {
    setLocale(userLoc);
  }, []);
  return (
    <ErrorBoundary>
      <Spin spinning={!!loading}>
        <Wrapper />
        <Header />

        <Routes>
          <Route path="/auth" element={<PublicOutlet />}>
            <Route index element={<Login />} />
          </Route>

          <Route path="/app" element={<PrivateOutlet />}>
            <Route index element={<UserLogin />} />
          </Route>

          <Route path="/app/user" element={<UserOutlet />}>
            <Route index element={<UserHome />} />
            <Route path="check_in" element={<CheckIn />} />
            <Route path="check_out" element={<CheckOut />} />
          </Route>

          <Route path="*" element={<Navigate to="/app" />} />
        </Routes>
      </Spin>
    </ErrorBoundary>
  );
};

export default App;

const Wrapper = () => {
  const token = useSelector((state) => state.auth.refresh_token);
  const envId = useSelector((state) => state.auth.envId);
  useEffect(() => {
    if (token && envId) {
      getTaxonomy();
    }
  }, [token]);

  useEffect(() => {
    store.dispatch({ type: "INIT_APP" });
    store.dispatch({ type: "STOP_LOADING" });
  }, []);

  return null;
};
