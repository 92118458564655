import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { login } from "actions/auth";
import { Input, RawDropdownPicker } from "@eitje/form-fields-web";
import { ContextForm as Form, useForm } from "@eitje/form";
import { useCookies } from "react-cookie";
import utils from "@eitje/web_utils";
import I18n, { t } from "initializers/i18n";
import { useSelector } from "react-redux";
import Dropdown from "components/dropdown";
import { logout } from "actions/auth";
import "./styles/header.less";
import { setLocale } from "actions/general";

const Header = () => {
  const hasAuth = useSelector((state) => state.auth.refresh_token);

  return (
    <div className="header">
      <div className="header-inner">
        <img
          className="logo"
          src="/images/logo_and_name.png"
          alt="eitje logo"
        />
        {hasAuth ? <AuthHeader /> : <BasicHeader />}
      </div>
    </div>
  );
};

const lngs = ["en", "nl"];

const LanguagePicker = () => {
  const locale = I18n.language;
  const userLoc = useSelector((state) => state.general.language);
  return (
    <RawDropdownPicker
      innerClass="language-picker"
      style={{ width: "120px", minWidth: "120px" }}
      value={userLoc}
      onChange={(loc) => setLocale(loc)}
      field="locale"
      items={lngs}
    />
  );
};

const AuthHeader = () => {
  const envName = useSelector((state) => state.taxonomy.env_name);
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="header-right-side auth">
      <h4 className="environment-name"> {envName} </h4>
      {user?.is_manager && <LogoutDropdown />}
      <LanguagePicker />
    </div>
  );
};

const LogoutDropdown = () => {
  const elements = [<Button onClick={logout}> {t("logout")} </Button>];
  return (
    <Dropdown className="options-dropdown" elements={elements}>
      <div className="options-button">
        <img className="options-icon" src="/icons/buttons/option_dots.png" />
      </div>
    </Dropdown>
  );
};

const BasicHeader = () => {
  return (
    <div className="header-right-side no-auth">
      <h4 className="not-activated"> {t("notActivated")} </h4>
      <LanguagePicker />
    </div>
  );
};

export default Header;
