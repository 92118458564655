import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import en from "data/locales/en/en.json";
import nl from "data/locales/nl/nl.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { translations as formTranslations } from "@eitje/form";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: { ...formTranslations.en, ...en },
      },
      nl: {
        translation: { ...formTranslations.nl, ...nl },
      },
    },
    fallbackLng: "en",
    debug: false,
    keySeparator: ".",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: "'",
    },
  });

export default i18n;

export const t = (text, opts = {}) => i18n.t(text, opts);
