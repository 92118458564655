import React, { Fragment, useEffect, useState, useRef } from "react";
import Login from "pages/auth/login";
import NotFound from "pages/system/not_found";
import { Route, Routes, Navigate } from "react-router-dom";
import { PrivateOutlet, PublicOutlet, UserOutlet } from "components/routes";
import ErrorBoundary from "components/error_boundary";
import { logout } from "actions/auth";
import { Button } from "antd";
import Header from "components/header";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Input } from "antd";
import { useAsyncEffect } from "@eitje/react-hooks";
import "./styles/code_input.less";

const NumEntry = ({ num, onChange }) => {
  return (
    <div className="code-input-number" onClick={() => onChange(num)}>
      <p className="code-input-number-text">{num}</p>
    </div>
  );
};

const NumPad = ({ onChange, onRemove }) => {
  const entries = _(9).times((i) => (
    <NumEntry key={i} onChange={onChange} num={i + 1} />
  ));
  const chunks = _.chunk(entries, 3);

  return (
    <div className="code-input">
      <div className="code-input-columns">
        {chunks.map((c, i) => (
          <div className="code-input-column" key={i}>
            {c}
          </div>
        ))}
      </div>
      <div className="zero-row">
        <NumEntry num={0} onChange={onChange} />
        <div className="code-input-number code-input-backspace" onClick={onRemove}>
          <img className="num-pad-icon" src="/icons/buttons/black_cross.png" />
        </div>
      </div>
    </div>
  );
};

const CodeInput = ({
  def = "",
  rollbackOnFail,
  autoSubmit = true,
  amtInputs = 5,
  focusInput = { current: null },
  renderPad,
  placeholder = "pin",
  onSubmit = _.noop,
  afterChange = _.noop,
  numOnly,
  autoFocus,
  ...rest
}) => {
  const [val, setVal] = useState(def);
  const appendToVal = (v) => setVal(`${val}${v}`);
  const addVal = (v) => {
    if (val.length >= amtInputs) return;
    appendToVal(v);
  };
  const shiftFromVal = () => setVal(val.slice(0, val.length - 1));
  const input = useRef(null);

  useAsyncEffect(async () => {
    await afterChange(val);
    if (val.length == amtInputs && autoSubmit) {
      _onSubmit(val);
    }
  }, [val]);

  const _onSubmit = async (val) => {
    const res = await onSubmit(val);
    if (!res?.ok && rollbackOnFail) {
      setVal(def);
    }
  };

  const _focusInput = () => input.current.focus();
  focusInput.current = _focusInput;

  const onPress = () => {
    setTimeout(() => input.current.focus(), 0);
  };

  return (
    <Fragment>
      <div className="password-display" onClick={onPress}>
        {_(amtInputs).times((i) => (
          <CodeCell key={i} act={val.length == i + 1} val={val[i]} i={i} />
        ))}
      </div>

      {renderPad && <NumPad onRemove={shiftFromVal} onChange={addVal} />}

      <Input.Password
        autoFocus={autoFocus}
        secure
        maxLength={amtInputs}
        ref={input}
        placeholder={placeholder}
        placeholderTextColor="#ccc"
        onChange={(e) => setVal(e.target.value)}
        hideCharCounter
        {...rest}
        value={val}
      />
    </Fragment>
  );
};

class CodeCell extends React.Component {
  state = { visible: true };

  componentDidUpdate = (prevProps) => {
    if (prevProps.act && !this.props.act) {
      this.setState({ visible: false });
    }
    if (this.props.val && !prevProps.val) {
      setTimeout(() => this.setState({ visible: false }), 800);
    }

    if (prevProps.val && !this.props.val) {
      this.setState({ visible: true });
    }
  };

  render = () => {
    const { i, act, val } = this.props;
    const { visible } = this.state;
    return (
      <div className="password-display-number">
        <p className="password-display-number-text">
          {val ? (visible ? val : "•") : ""}
        </p>
      </div>
    );
  };
}

export default CodeInput;
