import React, { useEffect, Fragment, useState } from "react";
import { Button } from "antd";
import { login } from "actions/auth";
import { Input } from "@eitje/form-fields-web";
import { ConpForm as Form, useForm } from "@eitje/form";
import { useCookies } from "react-cookie";
import utils from "@eitje/web_utils";
import { t } from "initializers/i18n";
import { useSelector } from "react-redux";
import { UserImage } from "components/ui";
import { preCheckIn } from "actions/check_in";
import { store } from "index";
import { history } from "index";
import { checkInSelector, prevCheckInSelector } from "selectors/check_in";
import { useLocation, useNavigate } from "react-router-dom";
import { useCountup } from "@eitje/react-hooks";
import BackFooter from "components/back_footer";
import _ from "lodash";
import "./styles/home.less";

const makeTeamName = (item) => {
  const { team, skill_set } = item;
  const { naam } = team;
  if (skill_set) return `${skill_set.name} - ${naam}`;
  return naam;
};

const Note = ({ note }) => (
  <div className="shift-note">
    <p className="shift-note-text">{note || t("noNote")}...</p>
  </div>
);

const Shift = ({ item }) => {
  const user = useSelector((state) => state.auth.user) || {};
  const { from, till, remarks, team, skill_set = {} } = item;
  const { color_code = "#0496ff" } = skill_set;
  const { full_name } = user;
  return (
    <Fragment>
      <div className="user-preview">
        <div className="user-preview-text">
          <h5>{from}</h5>
          <h5>{till}</h5>
        </div>

        <UserImage />
        {skill_set.id && (
          <div className="user-skill">
            <h5 className="user-skill-letter">{skill_set.abbr}</h5>
          </div>
        )}

        <div className="user-preview-text">
          <h4>{full_name}</h4>
          <p className="team-and-skill-set-text">{makeTeamName(item)}</p>
        </div>
      </div>

      <Note note={remarks} />
    </Fragment>
  );
};

const Home = () => {
  const user = useSelector((state) => state.auth.user) || {};

  useEffect(() => {
    preCheckIn();
    return () => {
      store.dispatch({ type: "RESET_PREV_CHECK_IN" });
    };
  }, []);

  const shift = useSelector((state) => state.records.nextShift);
  const checkIn = useSelector(checkInSelector);
  const loading = useSelector((state) => state.api.loading);
  const navigate = useNavigate();

  return (
    <div className="home-page">
      <div className="center-page-inner">
        <CheckIn />
        {shift && <Shift item={shift} />}
        {!shift && <User item={user} />}

        <div className="shift-buttons">
          {!checkIn && (
            <ShiftButton
              asset="/icons/time/stopwatch_start.png"
              disabled={loading || checkIn}
              title={t("startShift")}
              body={t("startShift2")}
              onPress={() => navigate("check_in", { replace: true })}
            />
          )}

          {checkIn && <CheckedInButton item={checkIn} />}

          {
            <ShiftButton
              asset="/icons/time/stopwatch_end.png"
              disabled={loading || !checkIn}
              title={t("endShift")}
              body={t("endShift2")}
              onPress={() => navigate("check_out", { replace: true })}
            />
          }
        </div>

        <BackFooter />
      </div>
    </div>
  );
};

const CheckIn = () => {
  const checkIn = useSelector(checkInSelector);
  const prevCheckIn = useSelector(prevCheckInSelector);
  const { checkedIn } = useLocation();

  if (!checkIn && !prevCheckIn) return null;

  const ci = prevCheckIn || checkIn;
  const p = ci.endsAt
    ? "checkOutSucc"
    : checkedIn
    ? "checkInSucc"
    : "checkInActive";

  const className = ci.endsAt
    ? "check-out-success"
    : checkedIn
    ? "check-in-success"
    : "check-in-active";

  return (
    <div className="check-in-status">
      <div className="worked-times-overview">
        <h1 className="time-text">{ci.start}</h1>
        {ci.endsAt && (
          <Fragment>
            <p className="time-divider">|</p>
            <h1 className="time-text">{ci.endsAt}</h1>
          </Fragment>
        )}
      </div>

      <div className="status-label">
        <div className="status-label-contents">
          {(ci.endsAt || checkedIn) && (
            <img
              className="status-label-icon"
              src="/icons/status/complete.png"
            />
          )}
          <p className={`status-label-text ${className}`}>{t(p)}</p>
        </div>
      </div>
    </div>
  );
};

const CheckedInButton = ({ item }) => {
  const timeElapsed = useCountup({ defaultTime: item.starts_at });
  return (
    <Fragment>
      <ShiftButton
        asset="/icons/time/stopwatch_start.png"
        title={utils.secondsToTimeString(timeElapsed)}
        body={`${t("checked_in_at")} ${item.start}`}
      />
    </Fragment>
  );
};

const ShiftButton = ({ disabled, title, body, asset, onPress }) => {
  return (
    <div
      disabled={disabled}
      onClick={disabled ? _.noop : onPress}
      style={{ opacity: disabled ? 0.25 : 1 }}
      className="shift-button"
    >
      <div className="shift-button-icon-container">
        <img className="shift-button-icon" src={asset} />
      </div>
      <div className="shift-button-text">
        <h3 className="shift-button-text-title">{title}</h3>
        <p className="shift-button-text-body">{body}</p>
      </div>
    </div>
  );
};

const User = ({ item }) => (
  <div className="user-preview">
    <UserImage />
    <div className="user-preview-text">
      <h4>{item.full_name}</h4>
    </div>
  </div>
);

export default Home;
