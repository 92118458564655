import auth from "reducers/auth";
import taxonomy from "reducers/taxonomy";
import records from "reducers/records";
import general from "reducers/general";

import { apiReducer } from "@eitje/easy_api";

const reducers = { auth, records, api: apiReducer, taxonomy, general };

export default reducers;
