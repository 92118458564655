import { createSelector } from "reselect";
import moment from "moment";

export const checkInSelector = createSelector(
  (state) => state.records.checkIn,
  (ci) => mapCi(ci)
);

export const prevCheckInSelector = createSelector(
  (state) => state.records.prevCheckIn,
  (ci) => mapCi(ci)
);

const mapCi = (ci) => {
  if (!ci) return;
  return {
    ...ci,
    start: moment(ci.starts_at).format("HH:mm"),
    end: moment(ci.ends_at).format("HH:mm"),
    startMoment: moment(ci.starts_at),
  };
};
