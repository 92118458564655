import React, { useEffect, Fragment, useState } from "react";
import { Button } from "antd";
import { login } from "actions/auth";
import { Input } from "@eitje/form-fields-web";
import { ConpForm as Form, useForm } from "@eitje/form";
import { useCookies } from "react-cookie";
import utils from "@eitje/web_utils";
import { t } from "initializers/i18n";
import { useSelector } from "react-redux";
import { UserImage } from "components/ui";
import { preCheckIn } from "actions/check_in";
import { store } from "index";
import { history } from "index";
import { checkInSelector, prevCheckInSelector } from "selectors/check_in";
import { useLocation } from "react-router-dom";
import { useCountdown } from "@eitje/react-hooks";
import "./styles/back_footer.less";

const BackToLogin = () => {
  const secondsLeft = useCountdown(20);

  useEffect(() => {
    const timeout = setTimeout(kickUser, 20000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div className="back-footer" onClick={kickUser}>
      <img className="door-icon" src="/icons/time/door.png" />
      <p>
        {t("backToLogin", { seconds: secondsLeft })}{" "}
        {t("secondsToClose", { seconds: secondsLeft })}
      </p>
    </div>
  );
};

const kickUser = () => {
  store.dispatch({ type: "RESET_USER" });
};

export default BackToLogin;
