import { setup, initApi } from "@eitje/easy_api";
import { store } from "index";
import { t } from "initializers/i18n";
import { url } from "environment";
import { errNotif } from "utils/ui";
import { refreshTokenIfNeeded } from "actions/auth";
import Reactotron from "reactotron-react-js";
import utils from "@eitje/web_utils";

let backend;

const ignoreErrors = (res) => {
  if (res.config.url.includes("oauth/token")) return true;
};

const handleAuth = (res) => {
  let content;
  if (!res.config.url.includes("oauth/token") || res.ok) return;
  const isUserReq = res.config.url.includes("user_specific");

  if (res.status == 401) {
    content = isUserReq ? t("wrongPin") : t("wrongLogin");
  }
  if (res.status == 403) content = t("wrongDevice");
  if (res.status == 500) content = res?.data?.errors || t("unknownErr");
  if (!res.status) content = t("noConnection");

  utils.errorNotif({ description: content });
};

const addUserHeader = (req) => {
  const state = store.getState();
  const { user_token } = state.auth;
  if (user_token) {
    if (!req.params) req.params = {};
    if (req.params) req.params["user_access_token"] = user_token;
  }
};

const init = () => {
  setup({
    t,
    store,
    ignoreErrors,
    logout: () => null,
    baseURL: url,
    alert: errNotif,
    getRefreshToken: refreshTokenIfNeeded,
  });

  backend = initApi();
  backend.addMonitor(handleAuth);
  backend.addMonitor(Reactotron.apisauce);
  backend.addRequestTransform((request) => addUserHeader(request));
};

export { init, backend };
