import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { checkAuth } from "actions/auth";
import { useSelector } from "react-redux";

export const PrivateRoute = ({ element, ...rest }) => (
  <Route
    {...rest}
    element={checkAuth() ? element : <Navigate replace to="/login" />}
  />
);

export const PublicRoute = ({ element, ...rest }) => {
  const _element = checkAuth() ? <Navigate replace to="/home" /> : element;
  return <Route {...rest} element={_element} />;
};

export function PrivateOutlet() {
  const auth = useSelector((state) => state.auth.refresh_token);
  const userAuth = useSelector((state) => state.auth.user_token);
  if (userAuth) return <Navigate to="/app/user" />;

  return auth ? <Outlet /> : <Navigate to="/auth" />;
}

export function PublicOutlet() {
  const auth = useSelector((state) => state.auth.refresh_token);
  return !auth ? <Outlet /> : <Navigate to="/app" />;
}

export function UserOutlet() {
  const auth = useSelector((state) => state.auth.user_token);
  return auth ? <Outlet /> : <Navigate to="/app" />;
}
