const nodeStage = process.env.NODE_ENV;
const envStage = process.env.REACT_APP_STAGE;

const stage = envStage || nodeStage;

const prefEnv = "development";
let host;
const actualEnv = nodeStage === "development" ? envStage || prefEnv : stage;
const ENV = actualEnv;
let domain;
let protocol = "https://";
switch (actualEnv) {
  case "development":
    domain = "localhost:3000/api/floor";
    host = `http://${domain}`;
    break;
  case "test":
    domain = `testapi.eitje.app/api/floor/`;
    host = `https://${domain}`;
    break;

  case "test2":
    domain = "eitje-test-api2.eitje.app/api/floor";
    host = `https://${domain}`;
    break;

  case "staging":
    domain = "staging-api.eitje.app/api/floor";
    host = `https://${domain}`;
    break;

  case "mirror":
    domain = `mirror-api.eitje.app/api/floor/`;
    host = `https://${domain}`;
    break;

  case "production":
    domain = `api.eitje.app/api/floor/`;
    host = `https://${domain}`;
    break;
}

const url = host;

export { url, ENV };
