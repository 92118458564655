import React, { useEffect, useState } from "react";
import { Button, Drawer } from "antd";
import { login } from "actions/auth";
import { Input } from "@eitje/form-fields-web";
import { ContextForm as Form, useForm } from "@eitje/form";
import { useCookies } from "react-cookie";
import utils from "@eitje/web_utils";
import { t } from "initializers/i18n";

const FindLoginKey = ({ onCancel }) => {
  return (
    <Drawer visible onClose={onCancel}>
      <p> {t("Key1")} </p>
      <p> {t("Key2")} </p>
      <p> {t("Key3")} </p>
      <p> {t("Key4")} </p>
    </Drawer>
  );
};

export default FindLoginKey;
