const initialState = {
  language: "nl",
};

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.language,
      };
    default:
      return state;
  }
}
